import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ContainerTypeDTO } from "../../../models/container-type";
import { SiteDTO } from "../../../models/site";
import { ContainerTypeControllerService } from "../../../services/container-type-controller.service";
import { ContainerByProvisionner } from "../../../services/device-container-controller.service";
import { UPDATE_TYPE } from "../../../stores/data_store/containers-type-store";
import { MacAddressControllerService } from "../../../services/mac-address-controller.service";
import { MacAddressDTO } from "../../../models/mac-address";
import Plotly from "plotly.js-dist-min";
import { CurrentStateDTO } from "../../../models/current-state";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { ColorService } from "../../../services/color.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-site-sidenav",
  templateUrl: "./site-sidenav.component.html",
  styleUrls: ["./site-sidenav.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0,0,0)"
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(100%, 0, 0)"
        })
      ),
      transition("in => out", animate("300ms ease-in-out")),
      transition("out => in", animate("300ms ease-in-out"))
    ])
  ]
})
export class SiteSidenavComponent implements OnChanges, OnDestroy {
  @Input() datas: any;
  @Input() site: SiteDTO;
  @Input() isVisible: boolean = false;

  public openingState: string = "out";

  public containerTypeList: ContainerByProvisionner;
  public hasStatic: boolean = false;
  public staticTimeAverage: number;
  public totalDeviceNumber: number = 0;
  public siteTitle: string;
  public macAddressesMapped: boolean = false;

  private authorizedContainerTypes: ContainerTypeDTO[];
  private subscription: Subscription = new Subscription();

  private statusDescriptionLabels: string[] = [];
  private statusDescriptionValues: number[] = [];
  private statusDescriptionColors: string[] = [];

  public containerTypeLabels: string[] = [];
  public containerTypeValues: number[] = [];

  public alertLabels: string[] = [];
  public alertValues: number[] = [];
  public alertDays: number[] = [];

  public meanStaticDaysLabels: string[] = [];
  public meanStaticDaysValues: number[] = [];

  private pieChartLayout: object = {
    height: 241,
    width: 325,
    margin: {
      t: 16,
      b: 16,
      l: 16,
      r: 16
    },
    paper_bgcolor: this.colorService.colors.transparent,
    legend: {
      itemclick: false,
      itemdoubleclick: false
    },
    showlegend: true
  };

  private pieChartConfig: object = {
    displaylogo: false,
    modeBarButtonsToRemove: ["toImage"]
  };

  private verticalBarChartLayout: object = {
    height: 107.5,
    width: 325,
    margin: {
      t: 0,
      b: 16,
      l: 16,
      r: 16
    },
    paper_bgcolor: this.colorService.colors.transparent,
    plot_bgcolor: this.colorService.colors.transparent,
    dragmode: false,
    xaxis: {
      type: "category",
      tickfont: {
        size: 10
      },
      automargin: true,
      fixedrange: true
    },
    yaxis: {
      tickfont: {
        size: 10
      },
      fixedrange: true,
      showline: false,
      showticklabels: false
    }
  };

  private verticalBarChartConfig: object = {
    displayModeBar: false,
    displaylogo: false,
    modeBarButtonsToRemove: ["toImage"]
  };

  private statusBarChartLayout: object = {
    height: 55,
    width: 325,
    margin: {
      t: 0,
      b: 20,
      l: 0,
      r: 0
    },
    paper_bgcolor: this.colorService.colors.transparent,
    plot_bgcolor: this.colorService.colors.transparent,
    dragmode: false,
    barmode: "stack",
    showlegend: false,
    xaxis: {
      fixedrange: true,
      automargin: true,
      tickfont: {
        size: 10
      },
      tickvals: [0, 25, 50, 75, 100],
      ticktext: ["0%", "25%", "50%", "75%", "100%"]
    },
    yaxis: {
      fixedrange: true,
      automargin: true,
      tickfont: {
        size: 10,
        color: this.colorService.colors.transparent
      }
    }
  };

  private statusBarChartConfig: object = {
    displayModeBar: false,
    displaylogo: false,
    modeBarButtonsToRemove: ["toImage"]
  };

  constructor(
    private store: Store<any>,
    private containerTypeControllerService: ContainerTypeControllerService,
    private macAddressControllerService: MacAddressControllerService,
    private colorService: ColorService,
    private translate: TranslateService
  ) {}

  ngOnChanges() {
    if (!this.isVisible || !this.site || !this.datas) {
      this.clearSiteData();
      return;
    }

    this.siteTitle = `${this.site.label} (${this.site.code})`;
    this.subscription.add(
      this.store
        .select("ctTypes")
        .subscribe((ctTypesData: ContainerTypeDTO[]) => {
          this.authorizedContainerTypes = [...ctTypesData];
        })
    );
    if (this.datas != undefined) this.initDatas();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initDatas() {
    let staticTimes: number[] = [];
    for (let i = 0; i < this.datas.partialCurrentStates.length; i++) {
      staticTimes.push(this.datas.partialCurrentStates[i].staticTime);
    }
    this.staticTimeAverage = this.datas.staticTimeAverage;

    this.containerTypeList = {
      keys: this.datas.containerTypes,
      provisioners: [""],
      assemblyNumber: [this.datas.containerTypesNumbers]
    };
    if (staticTimes.length !== 0) {
      this.hasStatic = true;
    }

    this.subscription.add(
      this.macAddressControllerService
        .get(0)
        .subscribe((macAddresses: MacAddressDTO[]) => {
          if (macAddresses?.length) {
            this.macAddressesMapped = !!macAddresses.find(
              (item) => item.siteId === this.site.code
            );
            this.initializeData();
            this.createContainerInfoGraphs();
            this.createVerticalBarCharts();
            this.toggleSiteSidenav();
          }
        })
    );
  }

  cTypeFilterUpdated(event: any) {
    if (event.checked) {
      const newContainerTypes: ContainerTypeDTO[] =
        this.authorizedContainerTypes.map((containerType) => {
          if (
            this.datas.authorizedContainerTypes.includes(containerType.code)
          ) {
            containerType.checked = true;
          } else {
            containerType.checked = false;
          }
          return containerType;
        });
      this.store.dispatch({ type: UPDATE_TYPE, payload: newContainerTypes });
      this.subscription.add(
        this.containerTypeControllerService
          .setPreferredContainerTypes(this.datas.authorizedContainerTypes)
          .subscribe((_res) => {})
      );
    }
  }

  public verifyContainerTypesEquals(cts: ContainerTypeDTO[]): boolean {
    const containerTypes: ContainerTypeDTO[] = cts.filter(
      (containerType) => containerType.checked
    );
    let siteAuthorizedCTypesByUser = cts.filter((containerType) =>
      this.datas.authorizedContainerTypes.includes(containerType.code)
    );
    let isEquals: boolean =
      containerTypes.length === siteAuthorizedCTypesByUser.length;
    for (const containerType of containerTypes) {
      if (
        isEquals &&
        !this.datas.authorizedContainerTypes.includes(containerType.code)
      ) {
        isEquals = false;
      }
    }
    return isEquals;
  }

  public getContainerTypeDistribution(): void {
    this.containerTypeValues = this.containerTypeList.assemblyNumber[0];
    this.containerTypeLabels = this.containerTypeList.keys;
  }

  public getStatusDistribution(): void {
    let statusDescriptionSet: { [key: string]: number } = {};

    this.datas.partialCurrentStates.forEach((currentState: CurrentStateDTO) => {
      let statusDescription = currentState.statusDescription.toUpperCase();
      const warningStatusDescription = ["STATIC", "LATE", "DETOUR"];
      if (warningStatusDescription.includes(statusDescription))
        statusDescription = "WARNING";
      if (statusDescriptionSet[statusDescription]) {
        statusDescriptionSet[statusDescription]++;
      } else {
        statusDescriptionSet[statusDescription] = 1;
      }
    });

    const statusDescriptionOrder: string[] = [
      "OK",
      "TRANSIT",
      "WARNING",
      "ALERT",
      "QUIET"
    ];
    statusDescriptionSet = Object.keys(statusDescriptionSet)
      .sort((a, b) => {
        return (
          statusDescriptionOrder.indexOf(a) - statusDescriptionOrder.indexOf(b)
        );
      })
      .reduce((newObject, key) => {
        newObject[key] = statusDescriptionSet[key];
        return newObject;
      }, {});

    this.statusDescriptionValues = Object.values(statusDescriptionSet);
    this.statusDescriptionLabels = Object.keys(statusDescriptionSet);

    this.statusDescriptionLabels.forEach((label) => {
      const color = this.colorService.getColorByStatusDescription(label);
      this.statusDescriptionColors.push(color);
    });
  }

  public getAlertDistribution(): void {
    const maxAlertBars = 5;

    let alertCurrentStates: CurrentStateDTO[] =
      this.datas.partialCurrentStates.filter(
        (currentState: CurrentStateDTO) =>
          currentState.statusDescription.toUpperCase() === "ALERT"
      );
    if (!alertCurrentStates.length) return;

    let containerTypeAlertSet: { [key: string]: number } = {};
    alertCurrentStates.forEach((currentState: CurrentStateDTO) => {
      const containerType = currentState.containerType;
      if (containerTypeAlertSet[containerType]) {
        containerTypeAlertSet[containerType]++;
      } else {
        containerTypeAlertSet[containerType] = 1;
      }
    });

    /* Sort labels and values with largest value first */
    const entries = Object.entries(containerTypeAlertSet).sort(
      ([, a], [, b]) => b - a
    );
    entries.slice(0, maxAlertBars).forEach((entry) => {
      this.alertLabels.push(entry[0]);
      this.alertValues.push(entry[1]);
      const containerTypeAlerts: CurrentStateDTO[] = alertCurrentStates.filter(
        (currentState: CurrentStateDTO) =>
          currentState.containerType === entry[0]
      );
      const meanAlertDays =
        containerTypeAlerts.reduce(
          (accumulator, currentState) => accumulator + currentState.statusDays,
          0
        ) / entry[1];
      this.alertDays.push(Math.round(meanAlertDays));
    });
  }

  public getStaticDaysDistribution(): void {
    const maxStaticBars = 5;

    let staticCurrentStates: CurrentStateDTO[] =
      this.datas.partialCurrentStates.filter(
        (currentState: CurrentStateDTO) =>
          currentState.statusDescription.toUpperCase() === "STATIC"
      );
    if (!staticCurrentStates.length) return;

    let staticDaysSet: { [key: string]: number } = {};
    staticCurrentStates.forEach((currentState) => {
      const containerType = currentState.containerType;
      const statusDays = currentState.statusDays ?? 0;
      if (staticDaysSet[containerType]) {
        staticDaysSet[containerType] += statusDays;
      } else {
        staticDaysSet[containerType] = statusDays;
      }
    });

    /* Compute mean of static days per container type */
    Object.keys(staticDaysSet).forEach((label) => {
      const containerTypeCurrentStates = staticCurrentStates.filter(
        (currentState: CurrentStateDTO) => currentState.containerType === label
      );
      staticDaysSet[label] = Math.round(
        staticDaysSet[label] / containerTypeCurrentStates.length
      );
    });

    /* Sort labels and values with largest value first */
    const entries = Object.entries(staticDaysSet).sort(([, a], [, b]) => b - a);
    entries.slice(0, maxStaticBars).forEach((entry) => {
      this.meanStaticDaysLabels.push(entry[0]);
      this.meanStaticDaysValues.push(entry[1]);
    });
  }

  public initializeData() {
    this.totalDeviceNumber = this.datas.partialCurrentStates.length;

    this.getStatusDistribution();
    this.getContainerTypeDistribution();

    this.getAlertDistribution();
    this.getStaticDaysDistribution();
  }

  public createContainerInfoGraphs(): void {
    this.createContainerTypePieChart();
    this.createStatusBarChart();
  }

  public createContainerTypePieChart(): void {
    if (!this.containerTypeLabels.length || !this.containerTypeValues.length)
      return;

    const data = [
      {
        values: this.containerTypeValues,
        labels: this.containerTypeLabels,
        type: "pie",
        textinfo: "value",
        hovertemplate: "%{label}: %{value} (%{percent})<extra></extra>",
        rotation: 90,
        textposition: "auto",
        outsidetextfont: { color: "transparent" }
      }
    ];

    Plotly.newPlot(
      "containerTypePieChart",
      data,
      this.pieChartLayout,
      this.pieChartConfig
    );
  }

  public createStatusBarChart(): void {
    if (!this.containerTypeLabels.length || !this.containerTypeValues.length)
      return;

    const data: object[] = [];

    this.translate.get("misc.status").subscribe((tStatus) => {
      this.statusDescriptionLabels.forEach((label, index) => {
        const statusData = {
          x: [
            (this.statusDescriptionValues[index] * 100) / this.totalDeviceNumber
          ],
          name: label,
          orientation: "h",
          marker: {
            color: this.statusDescriptionColors[index]
          },
          type: "bar",
          customdata: [this.statusDescriptionValues[index]],
          hovertemplate: `${tStatus[label]}: %{customdata} (%{x:.2f}%)<extra></extra>`,
          text: this.statusDescriptionValues[index].toString(),
          textposition: "auto",
          outsidetextfont: { color: "transparent" }
        };
        data.push(statusData);
      });

      Plotly.newPlot(
        "statusBarChart",
        data,
        this.statusBarChartLayout,
        this.statusBarChartConfig
      );
    });
  }

  public createVerticalBarCharts(): void {
    this.createAlertBarChart();
    this.createStaticBarChart();
  }

  public createAlertBarChart(): void {
    if (!this.alertLabels.length || !this.alertValues.length) return;

    this.translate.get("sideSite").subscribe((tSideSite) => {
      var data = [
        {
          x: this.alertLabels,
          y: this.alertValues,
          customdata: this.alertDays,
          type: "bar",
          hovertemplate: `%{customdata} ${tSideSite["days"]} (${tSideSite["mean"]})<br>%{y} ${tSideSite["devices"]}<extra></extra>`,
          text: this.alertValues.map(String),
          textposition: "auto",
          marker: {
            color: this.colorService.colors.redState
          }
        }
      ];

      Plotly.newPlot(
        "alertBarChart",
        data,
        this.verticalBarChartLayout,
        this.verticalBarChartConfig
      );
    });
  }

  public createStaticBarChart(): void {
    if (!this.meanStaticDaysLabels.length || !this.meanStaticDaysValues.length)
      return;

    const staticDaysDeviceNumber: number[] = this.meanStaticDaysLabels.map(
      (label: string) =>
        this.containerTypeValues[this.containerTypeLabels.indexOf(label)]
    );

    this.translate.get("sideSite").subscribe((tSideSite) => {
      var data = [
        {
          x: this.meanStaticDaysLabels,
          y: this.meanStaticDaysValues,
          customdata: staticDaysDeviceNumber,
          type: "bar",
          hovertemplate: `%{y} ${tSideSite["days"]}<br>%{customdata} ${tSideSite["devices"]}<extra></extra>`,
          text: this.meanStaticDaysValues.map(String),
          textposition: "auto",
          marker: {
            color: this.colorService.colors.orangeState
          }
        }
      ];

      Plotly.newPlot(
        "staticBarChart",
        data,
        this.verticalBarChartLayout,
        this.verticalBarChartConfig
      );
    });
  }

  public toggleSiteSidenav() {
    this.openingState = this.openingState === "out" ? "in" : "out";
  }

  public clearSiteData() {
    this.openingState = "out";
    this.statusDescriptionLabels = [];
    this.statusDescriptionValues = [];
    this.statusDescriptionColors = [];
    this.containerTypeLabels = [];
    this.containerTypeValues = [];
    this.alertLabels = [];
    this.alertValues = [];
    this.alertDays = [];
    this.meanStaticDaysLabels = [];
    this.meanStaticDaysValues = [];
    Plotly.purge("containerTypePieChart");
    Plotly.purge("statusBarChart");
    Plotly.purge("alertBarChart");
    Plotly.purge("staticBarChart");
  }
}
