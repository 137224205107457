import { DeviceContainerStateControllerService } from "../../../services/device-container-state-controller.service";
import { IncidentHistoricalControllerService } from "../../../services/incident-historical-controller.service";
import { IncidentControllerService } from "../../../services/incident-controller.service";
import { DataDecompressorService } from "../../../services/data-decompressor.service";
import { SiteControllerService } from "../../../services/site-controller.service";
import { userAccess } from "../../../services/user-rights.service";
import { GeoUtils } from "../../../services/geo-utils";
import {
  WebSocketInstance,
  WebSocketService
} from "../../../services/web-socket.service";

import { ContainerTypeDTO } from "../../../models/container-type";
import { CurrentStateDTO } from "../../../models/current-state";
import { IncidentDTO } from "../../../models/incident";
import { MapFilter } from "../../../models/map-filter";
import { DataMap } from "../../../models/data-map";
import { SiteDTO } from "../../../models/site";
import { WsData } from "../../../models";

import { StateDisplayerComponent } from "../../utils/state-displayer/state-displayer.component";
import { StatePopupComponent } from "../../utils/state-popup/state-popup.component";

import { UPDATE_STATUS } from "../../../stores/data_store/status-store";

import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  OnDestroy,
  Output
} from "@angular/core";
import * as L from "leaflet";
import "leaflet.markercluster";
import "leaflet.heat";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { ColorService } from "../../../services/color.service";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"]
})
export class MapComponent implements OnInit, OnDestroy {
  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  @Input() currentState: CurrentStateDTO;
  @Input() showDetailMap: boolean;
  @Input() selectedIndex: any;

  public showCreateIncident: boolean = false;

  public filterIncidentDevices: string[] = [];
  public filterLastSites: string[] = [];
  public filterIncidents: Number[] = [];
  public filterDevices: string[] = [];
  public filterContainers: any[] = [];
  public filterSiteCode: string;
  public filterStatusDays: string[] = [];
  public filterConfidenceRadius: string[] = [];
  public filterTorn: boolean = false;

  public stateDisplayerComponent: ComponentRef<StateDisplayerComponent>;
  public statePopupComponent: ComponentRef<StatePopupComponent>;
  public requestId: number = Math.floor(Math.random() * 200);
  public siteGroup: L.FeatureGroup = new L.FeatureGroup();
  public allContainerTypes: Array<ContainerTypeDTO>;
  public showSelectionCheckbox: boolean = false;
  public siteCurrentStates: CurrentStateDTO[];
  public reducedClusterDeviceIds: string[];
  public deviceDataOnMap: Array<DataMap>;
  public initialisation: boolean = false;
  public isSelectionOn: boolean = false;
  public mustRefreshMap: boolean = true;
  public clusterDeviceDatas: DataMap[];
  public markers: L.MarkerClusterGroup;
  public resultRequestId: number = -1;
  public siteMarkers: L.FeatureGroup;
  public site: SiteDTO | undefined;
  public filterIncident: any = {};
  public listSites: SiteDTO[];
  public listIncidents: IncidentDTO[] = [];
  public listAddIncident: IncidentDTO[] = [];
  public listIncidentDevices: CurrentStateDTO[] = [];
  public isEmptyIncident: boolean = true;
  public isEmptyIncidentDevice: boolean = true;

  public statusList: Array<any>;
  public mapFilter: MapFilter;
  public zoomSiteDatas: any;
  public mapReady: boolean;
  public siteLock: boolean;
  public clusterPopup: any;
  public hasSite: boolean;
  public loading: boolean;
  public time: number = 0;
  public center: L.Point;
  public radius: number;
  public scale: any;
  public map: any;

  public options = {
    zoom: 6,
    zIndex: 1,
    zoomControl: false,
    center: L.latLng([48, 2]),
    attributionControl: false,
    layers: [
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 18,
        minZoom: 4,
        updateWhenIdle: true
      })
    ]
  };

  private wsSubscription: Subscription = new Subscription();
  private readonly NAMESPACE: string = "incidentSelection";
  private subscription: Subscription = new Subscription();
  private timeoutId: NodeJS.Timeout;

  constructor(
    private ref: ChangeDetectorRef,
    private siteControllerServices: SiteControllerService,
    private deviceContainerStateControllerService: DeviceContainerStateControllerService,
    public _incidentHistoricalControllerService: IncidentHistoricalControllerService,
    private dataDecompressorService: DataDecompressorService,
    private store: Store<any>,
    private _geoUtils: GeoUtils,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    public incidentServices: IncidentControllerService,
    private webSocketService: WebSocketService,
    private colorService: ColorService
  ) {
    this.statusList = [];
    this.listSites = new Array<SiteDTO>();
    this.loading = true;
    this.initFilters();
  }

  initFilters() {
    if (JSON.parse(localStorage.getItem("containersFilter")) !== null) {
      this.filterContainers = JSON.parse(
        localStorage.getItem("containersFilter")
      );
    }
    if (JSON.parse(localStorage.getItem("devicesFilter")) !== null) {
      this.filterDevices = JSON.parse(localStorage.getItem("devicesFilter"));
    }
    if (JSON.parse(localStorage.getItem("lastSitesFilter")) !== null) {
      this.filterLastSites = JSON.parse(
        localStorage.getItem("lastSitesFilter")
      );
    }
    if (JSON.parse(localStorage.getItem("incidentsFilter")) !== null) {
      this.filterIncidents = JSON.parse(
        localStorage.getItem("incidentsFilter")
      );
      this.setIncidentDevicesId();
    }

    if (JSON.parse(localStorage.getItem("statusDaysFilter")) !== null) {
      this.filterStatusDays = JSON.parse(
        localStorage.getItem("statusDaysFilter")
      );
    }
    if (JSON.parse(localStorage.getItem("confidenceRadiusFilter")) !== null) {
      this.filterConfidenceRadius = JSON.parse(
        localStorage.getItem("confidenceRadiusFilter")
      );
    }

    if (JSON.parse(localStorage.getItem("mapFilter")) !== null) {
      this.mapFilter = JSON.parse(localStorage.getItem("mapFilter"));
      this.options.zoom = this.mapFilter.zoomLevel;
      this.options.center = L.latLng([
        this.mapFilter.centerLat,
        this.mapFilter.centerLng
      ]);
    } else {
      this.mapFilter = new MapFilter();
    }
  }

  saveFilter() {
    localStorage.setItem("mapFilter", JSON.stringify(this.mapFilter));
  }

  ngOnInit() {
    this.reInit({});
  }

  ngOnDestroy() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.subscription.unsubscribe();
    this.wsSubscription.unsubscribe();
    this.webSocketService.disconnect(this.NAMESPACE);
    this.freeMemory();
    this.map.removeLayer(this.siteGroup);
    this.siteGroup.clearLayers();
    this.siteGroup = undefined;
    this.map = undefined;
  }

  freeMemory() {
    if (this.markers) {
      this.map.removeLayer(this.markers);
      this.markers.clearLayers();
      this.markers = undefined;
    } // important to free memory
    if (this.siteMarkers) {
      this.map.removeLayer(this.siteMarkers);
      this.siteMarkers.clearLayers();
      this.siteMarkers = undefined;
    }
    if (this.statePopupComponent) {
      this.statePopupComponent.destroy();
      this.statePopupComponent = undefined;
    }
    if (this.clusterPopup) {
      this.map.removeLayer(this.clusterPopup);
      this.clusterPopup = undefined;
    }
  }

  onMapReady(map: L.Map) {
    if (map) {
      this.map = map;
      if (!this.currentState && !this.showDetailMap) {
        this.initDatas();
      } else {
        this.initStore();
        if (this.listSites.length === 0) {
          this.subscription.add(
            this.siteControllerServices.getSites().subscribe((sites) => {
              this.listSites = sites;
              this.initSite();
            })
          );
        }
      }

      const _this = this;
      setTimeout(function () {
        _this.map.invalidateSize();
        _this.mapReady = true;
      });

      if (!this.scale) {
        this.scale = new L.Control.Scale({
          position: "topleft",
          imperial: false,
          maxWidth: 200,
          updateWhenIdle: true
        }).addTo(this.map);
      }

      map.on("moveend", function (_ev) {
        _this.onMapChanged();
      });
    }
  }

  onMapChanged() {
    if (
      this.map &&
      !this.initialisation &&
      !this.showDetailMap &&
      !this.siteLock
    ) {
      this.map.invalidateSize();
      this.updateMap();
    }
  }

  addIncidentList(incident: IncidentDTO) {
    this.listAddIncident = [incident, ...this.listAddIncident];
    this.isEmptyIncident = false;
  }

  initSite() {
    for (const site of this.listSites) {
      const label: string =
        site.category !== "client"
          ? `<div style='align-items:center'>${site.label}</div> <div style='text-align:center'>${site.code}</div>`
          : site.label;
      const siteMarker = L.circle([site.latitude, site.longitude], {
        color: this.colorService.colors.siteMarker,
        fillColor: this.colorService.colors.siteMarker,
        fillOpacity: 0.4,
        radius: 50
      })
        .bindTooltip(label, {
          direction: "bottom",
          permanent: false,
          className: "leafletCustom",
          opacity: 1
        })
        .openTooltip()
        .addTo(this.siteGroup);

      siteMarker.on("mouseover", function (_e) {
        this.setRadius(site.toleranceRadiusInMeter);
        this.bringToBack();
      });
      siteMarker.on("mouseout", function (_e) {
        this.setRadius(50);
      });
    }
    this.map.addLayer(this.siteGroup);
    if (JSON.parse(localStorage.getItem("siteFilter")) !== null) {
      this.siteRequest(JSON.parse(localStorage.getItem("siteFilter")));
    }
  }

  initDatas() {
    this.initialisation = true;
    if (this.listSites.length === 0) {
      this.subscription.add(
        this.siteControllerServices.getSites().subscribe((res) => {
          this.listSites = res;
          this.initSite();
        })
      );
    }
    if (this.listIncidents.length === 0) {
      this.subscription.add(
        this.incidentServices.getIncident().subscribe((incidents) => {
          this.listAddIncident = incidents;
          this.incidentServices
            .checkCloseIncident(incidents)
            .subscribe((res) => {
              this.listIncidents = res;
              if (res.length) this.isEmptyIncident = false;
            });
        })
      );
    }
    this.initStore();
    this.initialisation = false;
    if (this.deviceDataOnMap) {
      this.refreshMap();
    } else {
      this.onMapChanged();
    }
  }

  initStore() {
    this.subscription.add(
      this.store.select("statusStore").subscribe((statusData: Array<any>) => {
        this.statusList = statusData;
        this.mustRefreshMap = true;
        this.resetPosition();
      })
    );

    this.subscription.add(
      this.store
        .select("ctTypes")
        .subscribe((ctTypesData: ContainerTypeDTO[]) => {
          this.allContainerTypes = ctTypesData;
          this.mustRefreshMap = true;
          this.resetPosition();
        })
    );
  }

  private setIncidentDevicesId(): void {
    this.loading = true;
    this.subscription.add(
      this.incidentServices
        .getDevicesIds(this.filterIncidents)
        .subscribe((devicesIds) => {
          this.filterIncidentDevices = devicesIds;
          this.mustRefreshMap = true;
          this.resetPosition();
        })
    );
  }

  private updateLocalStorage(key: string, data: any[]): void {
    data?.length
      ? localStorage.setItem(key, JSON.stringify(data))
      : localStorage.removeItem(key);
  }

  private updateStringLocalStorage(key: string, data: any): void {
    !data ? localStorage.removeItem(key) : localStorage.setItem(key, data);
  }

  changeContainers(containers) {
    this.updateLocalStorage("containersFilter", containers);
    this.filterContainers = containers;
    this.mustRefreshMap = true;
    this.resetPosition();
  }

  changeDevices(devices) {
    this.updateLocalStorage("devicesFilter", devices);
    this.filterDevices = devices;
    if (!this.filterIncidentDevices?.length) {
      this.mustRefreshMap = true;
      this.resetPosition();
    }
  }

  changeLastSites(sites) {
    this.updateLocalStorage("lastSitesFilter", sites);
    this.filterLastSites = sites;
    this.mustRefreshMap = true;
    this.resetPosition();
  }

  changeIncidents(incidentIds) {
    this.updateLocalStorage("incidentsFilter", incidentIds);
    this.filterIncidents = incidentIds;
    if (incidentIds?.length) {
      this.setIncidentDevicesId();
    } else {
      this.filterIncidentDevices = [];
      this.mustRefreshMap = true;
      this.resetPosition();
    }
  }

  changeStatusDays(statusDays: string[]) {
    this.updateLocalStorage("statusDaysFilter", statusDays);
    this.filterStatusDays = statusDays;
    this.mustRefreshMap = true;
    this.resetPosition();
  }

  changeConfidenceRadius(radius) {
    this.updateLocalStorage("confidenceRadiusFilter", radius);
    this.filterConfidenceRadius = radius;
    this.mustRefreshMap = true;
    this.resetPosition();
  }

  public changeTorn(torn) {
    this.filterTorn = torn;
    this.mustRefreshMap = true;
    this.resetPosition();
  }

  public closeIncidentCreation(): void {
    this.showCreateIncident = false;
  }

  changeIncidentDevices(incidentIds) {
    if (!incidentIds) {
      this.showCreateIncident = true;
    }
  }

  reInit(event: any) {
    if (event.showDetailMap !== undefined && event.currentState !== undefined) {
      this.showDetailMap = event.showDetailMap;
      this.currentState = event.currentState;
      this.refreshMap();
    } else {
      if (!this.showDetailMap && !this.currentState) {
        this.showDetailMap = false;
        this.currentState = null;
      }
    }
    this.onMapReady(this.map);
  }

  siteRequest(event?: string) {
    if (event) {
      this.siteLock = true;
      const site = this.listSites.find((site) => site.code === event);
      if (!this.site || this.site.code !== site.code) {
        this.site = site;
        this.hasSite = true;
        this.filterSiteCode = site.code;
        this.mustRefreshMap = true;
        this.updateMap(true);
      }
    } else {
      this.siteLock = false;
      this.mustRefreshMap = true;
      this.site = undefined;
      this.filterSiteCode = undefined;
      this.hasSite = false;
      this.resetPosition();
    }
  }

  updateGeopos() {
    const bounds = this.map.getBounds();
    if (!this.center && !this.radius) {
      this.center = new L.Point(
        this.map.getCenter().lng,
        this.map.getCenter().lat
      );
      this.radius =
        this.map.distance(bounds.getNorthEast(), bounds.getSouthWest()) / 2000;
      this.mustRefreshMap = !this.siteLock;
    } else {
      this.mapFilter = JSON.parse(localStorage.getItem("mapFilter"));
      if (!this.mapFilter) {
        this.mapFilter = new MapFilter();
      }
      this.mapFilter.centerLng = this.map.getCenter().lng;
      this.mapFilter.centerLat = this.map.getCenter().lat;
      this.mapFilter.zoomLevel = this.map.getZoom();
      this.saveFilter();
      const newCenter: L.Point = new L.Point(
        this.map.getCenter().lng,
        this.map.getCenter().lat
      );
      const newRadius: number =
        this.map.distance(bounds.getNorthEast(), bounds.getSouthWest()) / 2000;
      const distance: number =
        this.map.distance(
          { lat: this.center.y, lng: this.center.x },
          { lat: newCenter.y, lng: newCenter.x }
        ) / 2000;
      if (distance > this.radius / 2.5 || newRadius > 1.5 * this.radius) {
        this.radius = newRadius;
        this.center = newCenter;
        this.mustRefreshMap = !this.siteLock;
      }
    }
    const southWest = L.latLng(-90, -180);
    const northEast = L.latLng(90, 180);
    const boundsRestriction = L.latLngBounds(southWest, northEast);
    this.map.setMaxBounds(boundsRestriction);
  }

  resetPosition() {
    const bounds = this.map.getBounds();
    this.center = new L.Point(
      this.map.getCenter().lng,
      this.map.getCenter().lat
    );
    this.radius =
      this.map.distance(bounds.getNorthEast(), bounds.getSouthWest()) / 2000;
    this.updateMap();
  }

  updateMap(newSite?: boolean) {
    this.requestId++;
    this.loading = true;
    const id: number = this.requestId;
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.updateGeopos();
      if (this.requestId === id && (this.mustRefreshMap || newSite)) {
        this.loading = true;
        if (this.siteLock) {
          this.sendSiteRequest();
        } else {
          this.sendMapRequest();
        }
      } else {
        this.loading = !(this.requestId === id);
      }
    }, 1000);
  }

  sendSiteRequest() {
    this.subscription.add(
      this.deviceContainerStateControllerService
        .getCurrentStatesBySite({
          requestId: this.requestId,
          containerTypes: !this.filterContainers.length
            ? this.allContainerTypes
            : this.filterContainers,
          siteId: this.site.code,
          filtersColor: this.statusList,
          filterDevicesId: !this.filterIncidentDevices?.length
            ? this.filterDevices
            : this.filterIncidentDevices,
          filterLastSites: this.filterLastSites,
          filterIncident: this.filterIncident,
          filterConfidenceRadius: this.filterConfidenceRadius,
          filterStatusDays: this.filterStatusDays,
          filterTorn: this.filterTorn
        })
        .subscribe((result) => {
          this.dataDecompressorService
            .unpack(result.partialCurrentStates)
            .then((partialCurrentStates) => {
              if (result.requestId === this.requestId) {
                let siteResponse = {};
                for (const property in result) {
                  siteResponse[property] =
                    property === "partialCurrentStates"
                      ? partialCurrentStates
                      : result[property];
                }
                this.mustRefreshMap = false;
                this.siteCurrentStates = partialCurrentStates;
                if (this.loading) {
                  this.map.setView(
                    [this.site.latitude, this.site.longitude],
                    15
                  );
                }
                this.refreshMap(true);
                this.zoomSiteDatas = siteResponse;
                this.loading = false;
              }
            });
        })
    );
  }

  private async sendMapRequest() {
    this.time = Date.now();
    let timeRequest: number;
    this.subscription.add(
      this.deviceContainerStateControllerService
        .getAllCurrentStatesByContainerType({
          requestId: this.requestId,
          containerTypes: !this.filterContainers.length
            ? this.allContainerTypes
            : this.filterContainers,
          centerLat: this.center.y,
          centerLng: this.center.x,
          radius: this.radius,
          filtersColor: this.statusList,
          filterDevicesId: !this.filterIncidentDevices?.length
            ? this.filterDevices
            : this.filterIncidentDevices,
          filterLastSites: this.filterLastSites,
          filterIncident: this.filterIncident,
          filterStatusDays: this.filterStatusDays,
          filterConfidenceRadius: this.filterConfidenceRadius,
          filterTorn: this.filterTorn
        })
        .subscribe((result) => {
          if (result.requestId === this.requestId) {
            timeRequest = Date.now() - this.time;
            if (timeRequest > 5000)
              this.subscription.add(
                this.deviceContainerStateControllerService
                  .logSlow(
                    `[SLOWLOG][${localStorage.getItem(
                      "username"
                    )}][NETWORK] Recuperation : ${timeRequest / 1000}s reqId:${
                      this.requestId
                    }`
                  )
                  .subscribe((_r) => {})
              );
            this.mustRefreshMap = false;
            let tempsDecomp = Date.now();
            this.dataDecompressorService
              .unpack(result.partialCurrentStates)
              .then((result) => {
                this.deviceDataOnMap = result;
                timeRequest = Date.now() - this.time;
                tempsDecomp = Date.now() - tempsDecomp;
                console.log(
                  `[DECOMPRESSION] ${tempsDecomp} ms / temps total avant affichage cluster ${timeRequest} : `,
                  result
                );
                if (timeRequest > 6500)
                  this.subscription.add(
                    this.deviceContainerStateControllerService
                      .logSlow(
                        `[SLOWLOG][${localStorage.getItem(
                          "username"
                        )}][UNPACK] Décompression : ${
                          timeRequest / 1000
                        }s reqId:${this.requestId}`
                      )
                      .subscribe((_r) => {})
                  );
                this.resultRequestId = this.requestId;
                if (this.deviceDataOnMap.length === 1)
                  this.map.setView(
                    [
                      this.deviceDataOnMap[0].latitude,
                      this.deviceDataOnMap[0].longitude
                    ],
                    7
                  ); // center map on device when only one device
                this.refreshMap();
              });
          }
        })
    );
  }

  isVisible(marker: string): boolean {
    if (Array.isArray(this.statusList) && this.statusList.length > 0) {
      const obj = this.statusList.find((item) => item.marker === marker);
      if (!obj) {
        return false;
      }
      return obj.show;
    }
    // We should never fallback to this
    // console.log('No filters could be found, showing all');
    return true;
  }

  refreshMap(noCluster?: boolean) {
    if (!this.showDetailMap) {
      if (!Array.isArray(this.deviceDataOnMap) && !noCluster) {
        return;
      }
      if (noCluster === true) {
        this.showZoomOnSite(this.siteCurrentStates);
      } else {
        this.clusterizeContainers(this.deviceDataOnMap);
      }
    }
    this.loading = false;
    let timeRequest = Date.now() - this.time;
    if (this.resultRequestId !== -1) {
      console.log("temps total affichage map :", timeRequest);
      if (timeRequest > 9500)
        this.subscription.add(
          this.deviceContainerStateControllerService
            .logSlow(
              `[SLOWLOG][${localStorage.getItem(
                "username"
              )}][APP] Affichage clusters map: ${timeRequest / 1000} s reqId:${
                this.requestId
              }`
            )
            .subscribe((_r) => {})
        );
      this.resultRequestId = -1;
    }
  }

  showZoomOnSite(currentStateList: Array<CurrentStateDTO>) {
    const _this = this;
    this.freeMemory();
    let customMarker: any = L.Marker.extend({ color: "" });
    let customIcon: any = L.Icon.extend({
      options: {
        iconAnchor: [100, 100],
        iconSize: [40, 50],
        popupAnchor: [0, -50]
      }
    });
    this.siteMarkers = L.featureGroup();
    this.markers = L.markerClusterGroup({
      iconCreateFunction: function (cluster) {
        let customIconCluster: any = L.DivIcon.extend({});
        const childColors = [];
        let iconURL = "/assets/img/cluster.png";
        const count = cluster.getAllChildMarkers().length;
        for (const marker of cluster.getAllChildMarkers() as any) {
          childColors.push(marker.options.color);
          if (count === 1) {
            iconURL = `/assets/img/pin/${marker.options.color.toLowerCase()}.svg`;
          }
        }
        const mostFrequentColor = _this.getMostFrequentColor(childColors);

        if (
          ["RED", "ORANGE", "BLUE", "GREEN", "GREY"].includes(
            mostFrequentColor
          ) &&
          count > 1
        ) {
          iconURL = `/assets/img/cluster-${mostFrequentColor.toLowerCase()}.png`;
        }
        const divCluster = L.DomUtil.create("div", "divCluster");

        const divChildCount = L.DomUtil.create(
          "span",
          "childCount",
          divCluster
        );
        divChildCount.innerHTML =
          count === 1 ? null : cluster.getAllChildMarkers().length.toString();
        divChildCount.style.position = "absolute";
        divChildCount.style.zIndex = "100";
        divChildCount.style.top = "40%";
        divChildCount.style.left = "50%";
        divChildCount.style.fontSize = "medium";
        divChildCount.style.transform = "translate(-50%, -50%)";

        const divIconCluster = L.DomUtil.create(
          "img",
          "iconCluster",
          divCluster
        ) as HTMLImageElement;
        divIconCluster.src = iconURL;
        divIconCluster.style.width = "40px";
        divIconCluster.style.height = "50px";

        return new customIconCluster({
          html: divCluster.innerHTML,
          className: "div-cluster"
        });
      },

      singleMarkerMode: true,
      spiderfyOnMaxZoom: false,
      zoomToBoundsOnClick: false,
      showCoverageOnHover: false,
      maxClusterRadius: 40
    });
    // CLUSTER POPUP HERE
    this.markers.on("clusterclick", (e: any) => {
      const layers = e.layer.getAllChildMarkers();
      this.clusterDeviceDatas = [...layers]
        .map((layer) => layer.item)
        .sort((a: DataMap, b: DataMap) =>
          a.receivedMessageTime > b.receivedMessageTime
            ? -1
            : a.receivedMessageTime > b.receivedMessageTime
              ? 0
              : 1
        );
      let clusterDeviceIds: string[] = this.clusterDeviceDatas.map(
        (device) => device.deviceId
      );
      let first10DeviceIds: string[] = clusterDeviceIds.slice(0, 30);
      const popup = L.DomUtil.create("div", "popup-cluster");
      popup.style.width = "60vw";
      const statePopupFactory =
        this.resolver.resolveComponentFactory(StatePopupComponent);
      this.statePopupComponent = statePopupFactory.create(this.injector);
      this.appRef.attachView(this.statePopupComponent.hostView);
      this.statePopupComponent.onDestroy(() => {
        if (this.showCreateIncident) this.showCreateIncident = false;
        this.listIncidentDevices = [];
        this.isEmptyIncidentDevice = true;
        this.appRef.detachView(this.statePopupComponent.hostView);
      });
      popup.appendChild(this.statePopupComponent.location.nativeElement);
      var _this = this;
      this.clusterPopup = L.popup({
        closeButton: false,
        maxWidth: 1300,
        minWidth: 1300,
        offset: [0, -40]
      })
        .setLatLng(e.layer.getLatLng())
        .setContent(popup)
        .openOn(this.map)
        .on("remove", function () {
          _this.showSelectionCheckbox = false;
          _this.ref.detectChanges();
          if (_this.isSelectionOn) _this.disableSelectionMode();
          if (_this.statePopupComponent) _this.statePopupComponent.destroy();
        });

      this.subscription.add(
        this.deviceContainerStateControllerService
          .getClusterCurrentStates(first10DeviceIds)
          .subscribe((result) => {
            this.statePopupComponent.instance.currentStates = result;
            this.statePopupComponent.instance.clusterDeviceIds =
              clusterDeviceIds;
            this.statePopupComponent.instance.isSelectionOn =
              this.isSelectionOn;
            this.statePopupComponent.instance.clicked.subscribe(
              (currentState) => this.deviceIdClicked(currentState)
            );
            // gestion incident popup
            this.statePopupComponent.instance.handleDevice.subscribe((device) =>
              this.handleDeviceIncident(device)
            );
            this.statePopupComponent.changeDetectorRef.detectChanges();
            this.statePopupComponent.instance.clickedIncident.subscribe(
              (info) => this.navigate.emit(info)
            );
            let reducedClusterDeviceDatas = this.clusterDeviceDatas.filter(
              (element) =>
                element.statusColor === "RED" ||
                element.statusColor === "ORANGE"
            ); // && element.containerType !== "TORN")
            if (
              reducedClusterDeviceDatas.length >= 1 &&
              reducedClusterDeviceDatas.length <= 150
            ) {
              this.showSelectionCheckbox = true;
              this.reducedClusterDeviceIds = reducedClusterDeviceDatas.map(
                (device) => device.deviceId
              );
            }
          })
      );
    });
    let heatmapDataLatLng = currentStateList.map(
      ({ latitudeComputed, longitudeComputed }) => {
        return new L.LatLng(latitudeComputed, longitudeComputed);
      }
    );
    for (let currentState of currentStateList) {
      var heatmap = L.heatLayer(heatmapDataLatLng, { radius: 30, max: 5 });

      try {
        if (
          currentState.latitudeComputed !== undefined &&
          currentState.longitudeComputed !== undefined
        ) {
          const _this = this;
          let content: HTMLElement;
          const popupCluster = L.DomUtil.create("div", "popup");
          popupCluster.style.width = "60vw";
          const popup = L.popup({
            offset: [0, 15],
            closeButton: false,
            maxWidth: 768
          })
            .setLatLng([
              currentState.latitudeComputed,
              currentState.longitudeComputed
            ])
            .setContent(popupCluster)
            .on("remove", function () {
              if (content && _this.stateDisplayerComponent) {
                popupCluster.removeChild(content);
                _this.stateDisplayerComponent.destroy();
                content = undefined;
                _this.stateDisplayerComponent = undefined;
              }
            });
          const icon = this._geoUtils.getMarkerIcon(
            currentState.statusColor,
            currentState.statusColor === "GREY",
            false
          ); //currentState.locationSource ? currentState.locationSource.toUpperCase().includes('WIFI') : false);

          let marker = new customMarker(
            [currentState.latitudeComputed, currentState.longitudeComputed],
            {
              icon: new customIcon({ iconUrl: icon }),
              color: currentState.statusColor
            }
          )
            .bindPopup(popup)
            .closePopup();
          marker.item = currentState;
          marker.on("click", (e: any) => {
            if (popupCluster.childNodes.length === 0) {
              content = this.createPopup(e.target.item.deviceId);
              popupCluster.appendChild(content);
            }
          });

          this.markers.addLayer(marker);
        }
      } catch (err) {
        console.log("UNDEFINED : ", currentState, err);
      }
      this.siteMarkers.addLayer(this.markers);
    }
    if (heatmap) {
      this.siteMarkers.addLayer(heatmap);
    }
    this.map.addLayer(this.siteMarkers);
  }

  clusterizeContainers(deviceDatas: Array<DataMap>) {
    this.freeMemory();
    let time = Date.now();
    this.createClusters();
    let customMarker: any = L.Marker.extend({ color: "" });
    let customIcon: any = L.Icon.extend({
      options: {
        iconAnchor: [20, 50],
        iconSize: [40, 50],
        popupAnchor: [0, -50]
      }
    });
    let marker: any;
    for (const deviceData of deviceDatas) {
      try {
        if (
          deviceData.latitude !== undefined &&
          deviceData.longitude !== undefined
        ) {
          const _this = this;
          let content: HTMLElement;
          const popupCluster = L.DomUtil.create("div", "popup");
          popupCluster.style.width = "60vw";
          const popup = L.popup({
            offset: [0, 15],
            closeButton: false,
            maxWidth: 768
          })
            .setLatLng([deviceData.latitude, deviceData.longitude])
            .setContent(popupCluster)
            .on("remove", function () {
              if (content && _this.stateDisplayerComponent) {
                popupCluster.removeChild(content);
                _this.stateDisplayerComponent.destroy();
                content = undefined;
                _this.stateDisplayerComponent = undefined;
              }
            });
          const icon = this._geoUtils.getMarkerIcon(
            deviceData.statusColor,
            deviceData.statusColor === "GREY",
            false
          ); //currentState.locationSource ? currentState.locationSource.toUpperCase().includes('WIFI') : false);

          marker = new customMarker(
            [deviceData.latitude, deviceData.longitude],
            {
              icon: new customIcon({ iconUrl: icon }),
              color: deviceData.statusColor
            }
          )
            .bindPopup(popup)
            .closePopup();
          marker.item = deviceData;
          marker.on("click", (e: any) => {
            if (popupCluster.childNodes.length === 0) {
              content = this.createPopup(e.target.item.deviceId);
              popupCluster.appendChild(content);
            }
          });

          this.markers.addLayer(marker);
        }
      } catch (err) {
        console.log("UNDEFINED : ", deviceData, err);
      }
    }
    let time2 = Date.now();
    this.map.addLayer(this.markers);
    console.log(
      "affichage clusters",
      Date.now() - time2,
      "total",
      Date.now() - time
    );
  }

  onChangeTab(info: any) {
    this.navigate.emit(info);
  }

  getMostFrequentColor(array: string[]): string | null {
    if (!array.length) return null;
    const frequencyMap = {};
    let maxCount: number = 0;
    let mostFrequentColor: string | null = null;
    const colorPriority = {
      RED: 4,
      ORANGE: 3,
      BLUE: 2,
      GREEN: 1,
      GREY: 0
    };

    for (const color of array) {
      if (frequencyMap[color]) {
        frequencyMap[color]++;
      } else {
        frequencyMap[color] = 1;
      }
      if (
        frequencyMap[color] === maxCount &&
        colorPriority[color] > colorPriority[mostFrequentColor] &&
        color !== "GREY"
      ) {
        mostFrequentColor = color;
      } else if (frequencyMap[color] > maxCount && color !== "GREY") {
        maxCount = frequencyMap[color];
        mostFrequentColor = color;
      }
    }

    if ("GREY" in frequencyMap && frequencyMap["GREY"] === array.length) {
      return "GREY";
    } else {
      return mostFrequentColor;
    }
  }

  createClusters() {
    const _this = this;
    this.markers = L.markerClusterGroup({
      iconCreateFunction: function (cluster) {
        let customIconCluster: any = L.DivIcon.extend({});
        const childColors = [];
        let iconURL = "/assets/img/cluster.png";

        for (const marker of cluster.getAllChildMarkers() as any) {
          childColors.push(marker.options.color);
        }
        const mostFrequentColor = _this.getMostFrequentColor(childColors);
        if (
          ["RED", "ORANGE", "BLUE", "GREEN", "GREY"].includes(mostFrequentColor)
        ) {
          iconURL = `/assets/img/cluster-${mostFrequentColor.toLowerCase()}.png`;
        }
        const divCluster = L.DomUtil.create("div", "divCluster");

        const divChildCount = L.DomUtil.create(
          "span",
          "childCount",
          divCluster
        );
        divChildCount.innerHTML = cluster
          .getAllChildMarkers()
          .length.toString();
        divChildCount.style.position = "absolute";
        divChildCount.style.zIndex = "100";
        divChildCount.style.top = "40%";
        divChildCount.style.left = "50%";
        divChildCount.style.fontSize = "medium";
        divChildCount.style.transform = "translate(-50%, -50%)";

        const divIconCluster = L.DomUtil.create(
          "img",
          "iconCluster",
          divCluster
        ) as HTMLImageElement;
        divIconCluster.src = iconURL;
        divIconCluster.style.width = "40px";
        divIconCluster.style.height = "50px";

        return new customIconCluster({
          html: divCluster.innerHTML,
          className: "div-cluster"
        });
      },

      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false
    });

    // CLUSTER POPUP HERE
    this.markers.on("clusterclick", (e: any) => {
      const layers = e.layer.getAllChildMarkers();
      this.clusterDeviceDatas = [...layers]
        .map((layer) => layer.item)
        .sort((a: DataMap, b: DataMap) =>
          a.receivedMessageTime > b.receivedMessageTime
            ? -1
            : a.receivedMessageTime > b.receivedMessageTime
              ? 0
              : 1
        );
      let clusterDeviceIds: string[] = this.clusterDeviceDatas.map(
        (device) => device.deviceId
      );
      let first10DeviceIds: string[] = clusterDeviceIds.slice(0, 30);
      const popup = L.DomUtil.create("div", "popup-cluster");
      const statePopupFactory =
        this.resolver.resolveComponentFactory(StatePopupComponent);
      this.statePopupComponent = statePopupFactory.create(this.injector);
      this.appRef.attachView(this.statePopupComponent.hostView);
      this.statePopupComponent.onDestroy(() => {
        if (this.showCreateIncident) this.showCreateIncident = false;
        this.listIncidentDevices = [];
        this.isEmptyIncidentDevice = true;
        this.appRef.detachView(this.statePopupComponent.hostView);
      });
      popup.appendChild(this.statePopupComponent.location.nativeElement);
      var _this = this;
      this.clusterPopup = L.popup({
        closeButton: false,
        maxWidth: 1300,
        minWidth: 1300,
        offset: [0, -40]
      })
        .setLatLng(e.layer.getLatLng())
        .setContent(popup)
        .openOn(this.map)
        .on("remove", function () {
          _this.showSelectionCheckbox = false;
          _this.ref.detectChanges();
          if (_this.isSelectionOn) _this.disableSelectionMode();
          if (_this.statePopupComponent) _this.statePopupComponent.destroy();
        });

      this.subscription.add(
        this.deviceContainerStateControllerService
          .getClusterCurrentStates(first10DeviceIds)
          .subscribe((result) => {
            this.statePopupComponent.instance.currentStates = result;
            this.statePopupComponent.instance.clusterDeviceIds =
              clusterDeviceIds;
            this.statePopupComponent.instance.isSelectionOn =
              this.isSelectionOn;
            this.statePopupComponent.instance.clicked.subscribe(
              (currentState) => this.deviceIdClicked(currentState)
            );
            this.statePopupComponent.instance.clickedIncident.subscribe(
              (info) => this.navigate.emit(info)
            );
            this.statePopupComponent.instance.handleDevice.subscribe((device) =>
              this.handleDeviceIncident(device)
            );
            this.statePopupComponent.changeDetectorRef.detectChanges();

            let reducedClusterDeviceDatas = this.clusterDeviceDatas.filter(
              (element) =>
                element.statusColor === "RED" ||
                element.statusColor === "ORANGE"
            ); // && element.containerType !== "TORN")
            if (
              reducedClusterDeviceDatas.length >= 1 &&
              reducedClusterDeviceDatas.length <= 150
            ) {
              this.showSelectionCheckbox = true;
              this.reducedClusterDeviceIds = reducedClusterDeviceDatas.map(
                (device) => device.deviceId
              );
            }
          })
      );
    });
  }

  createPopup(deviceId: string) {
    let popupElement = L.DomUtil.create("div", "popup-cluster");
    const statePopupFactory =
      this.resolver.resolveComponentFactory(StatePopupComponent);
    this.statePopupComponent = statePopupFactory.create(this.injector);
    this.appRef.attachView(this.statePopupComponent.hostView);
    this.statePopupComponent.onDestroy(() => {
      this.appRef.detachView(this.statePopupComponent.hostView);
    });
    popupElement.appendChild(this.statePopupComponent.location.nativeElement);

    this.subscription.add(
      this.deviceContainerStateControllerService
        .getClusterCurrentStates([deviceId])
        .subscribe((result) => {
          this.statePopupComponent.instance.currentStates = result;
          this.statePopupComponent.instance.clusterDeviceIds = [deviceId];
          this.statePopupComponent.instance.clicked.subscribe((currentState) =>
            this.deviceIdClicked(currentState)
          );
          this.statePopupComponent.instance.clickedIncident.subscribe((info) =>
            this.navigate.emit(info)
          );
          this.statePopupComponent.instance.handleDevice.subscribe((device) =>
            this.handleDeviceIncident(device)
          );
          this.statePopupComponent.changeDetectorRef.detectChanges();
        })
    );

    return popupElement;
  }

  // Events

  private handleDeviceIncident(deviceData): void {
    const index = this.listIncidentDevices.findIndex(
      (device) => device.deviceId === deviceData.device.deviceId
    );

    if (deviceData.checked && index === -1) {
      this.listIncidentDevices = [
        ...this.listIncidentDevices,
        deviceData.device
      ];
    } else if (!deviceData.checked && index >= 0) {
      this.listIncidentDevices.splice(index, 1);
    }
    if (this.listIncidentDevices.length === 0) {
      this.isEmptyIncidentDevice = true;
    } else {
      this.isEmptyIncidentDevice = false;
    }
  }

  deviceIdClicked(id: CurrentStateDTO) {
    this.freeMemory();
    this.showSelectionCheckbox = false;
    this.showDetailMap = true;
    this.currentState = id;
  }

  updateFilter(filter: any) {
    this.store.dispatch({ type: UPDATE_STATUS, payload: filter });
  }

  public navChange(): void {
    this.navigate.emit({ tab: userAccess.LIST });
  }

  private updateAlertEvaluater(currentState: CurrentStateDTO) {
    this.subscription.add(
      this._incidentHistoricalControllerService
        .updateAlertEvaluater(currentState)
        .subscribe(() => {})
    );
  }

  private async updateSelectionAlertEvaluater(
    currentStates: Array<CurrentStateDTO>
  ) {
    try {
      const wsData: WsData = {
        messageType: "Init",
        referenceType: "CurrentStates",
        datas: currentStates,
        userName: ""
      };
      let webSocketInstance: WebSocketInstance =
        this.webSocketService.sockets.get(this.NAMESPACE);
      if (!webSocketInstance || !webSocketInstance.socket.connected) {
        await this.webSocketService.connect(this.NAMESPACE);
        this._incidentHistoricalControllerService.setIsLoading(0);
        this.wsSubscription = this.webSocketService
          .getNewMessage(this.NAMESPACE)
          .subscribe(async (result) => {
            if (result && result !== "") {
              const wsDataResponses = JSON.parse(result);
              if (wsDataResponses.loading) {
                this._incidentHistoricalControllerService.setIsLoading(
                  wsDataResponses.loading
                );
              }
              if (
                wsDataResponses.messageType === "Finish" ||
                wsDataResponses.messageType === "Error"
              ) {
                wsDataResponses.messageType === "Finish"
                  ? this._incidentHistoricalControllerService.setIsLoading(100)
                  : this._incidentHistoricalControllerService.setIsLoading(-1);
                await this.webSocketService.disconnect(this.NAMESPACE);
                this.wsSubscription.unsubscribe();
              }
            }
          });
      }
      await this.webSocketService.sendMessage(wsData, this.NAMESPACE);
    } catch (e) {
      console.log("Erreur update selection current states", e);
    }
  }

  public filterIncidentChange(event) {
    if (this.isSelectionOn && event.deviceId) {
      this.startRequestSelection(event, true);
      this.filterIncident = {};
    } else if (!this.isSelectionOn && event.deviceId) {
      this.updateAlertEvaluater(event);
      this.filterIncident = {};
    } else {
      this.filterIncident = event;
      this.mustRefreshMap = true;
      this.resetPosition();
    }
  }

  toList(event: any) {
    this.navigate.emit({
      tab: event.tab,
      display: event.display,
      currentStateList: event.currentState
    });
  }

  private onOpenCategory(event) {
    let incident: boolean = event.incident === true || event.incident === false;
    if (incident) {
      //prevent opening categorie for selection when there is no incident
      const filterIncident: any = { ...this.filterIncident };
      filterIncident.openCategory = event;
      this.filterIncident = filterIncident;
    }
    if (this.isSelectionOn && event.deviceId) {
      this.startRequestSelection(event, !incident);
    }
  }

  startRequestSelection(event, launchRequest: boolean) {
    this.subscription.add(
      this.deviceContainerStateControllerService
        .getClusterCurrentStates(this.reducedClusterDeviceIds)
        .subscribe((result) => {
          let sortedCurrentStates: CurrentStateDTO[] = [];
          let doFilter: boolean = false;
          for (const currentState of result)
            sortedCurrentStates.push({ ...currentState });
          for (const currentState of sortedCurrentStates) {
            if (
              (currentState.statusColor === "RED" &&
                currentState.containerType !== "TORN") ||
              currentState.statusColor === "ORANGE"
            ) {
              currentState.incident = event.incident;
            } else doFilter = true;
          }
          if (doFilter)
            sortedCurrentStates = sortedCurrentStates.filter(
              (element) =>
                (element.statusColor === "RED" &&
                  element.containerType !== "TORN") ||
                element.statusColor === "ORANGE"
            );
          let changes = { currentStates: [...sortedCurrentStates] };
          this.statePopupComponent.instance.ngOnChanges(changes);
          if (launchRequest) {
            //update selection of current state if no incident
            let selectionCurrentStates: Array<CurrentStateDTO> = [];
            for (const sortedReduceCurrentState of sortedCurrentStates) {
              selectionCurrentStates.push({ ...sortedReduceCurrentState });
            }
            this.updateSelectionAlertEvaluater(selectionCurrentStates);
          }
        })
    );
  }

  runSelectionMode(event) {
    event ? this.enableSelectionMode() : this.disableSelectionMode();
  }

  enableSelectionMode() {
    this.clusterPopup.getElement().style.backgroundColor =
      this.colorService.colors.defaultBlack;
    this.isSelectionOn = true;
    this.statePopupComponent.instance.clicked.isStopped = true;
    this.statePopupComponent.instance.isSelectionOn = this.isSelectionOn;
    //If you want to find a way to not close the popup when you click on the map or somewhere else :
    // this.map.on('click', function(e) {
    // }); add this in onMapReady, deactivate closePopupOnClick in map options at the creation and handle click event to close popup if selectionMode is on or not
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    if (this.map.tap) this.map.tap.disable();
    if (this.reducedClusterDeviceIds.length !== this.clusterDeviceDatas.length)
      alert(
        "Attention certains currentStates sélectionnés ne peuvent pas être labellisés"
      );
  }

  disableSelectionMode() {
    this.clusterPopup.getElement().style.backgroundColor =
      this.colorService.colors.transparent;
    this.isSelectionOn = false;
    this.statePopupComponent.instance.clicked.isStopped = false;
    this.statePopupComponent.instance.isSelectionOn = this.isSelectionOn;
    this.map.dragging.enable();
    this.map.touchZoom.enable();
    this.map.doubleClickZoom.enable();
    this.map.scrollWheelZoom.enable();
    this.map.boxZoom.enable();
    this.map.keyboard.enable();
    if (this.map.tap) this.map.tap.enable();
  }
}
