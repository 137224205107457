import { IncidentHistoricalControllerService } from "../../../services/incident-historical-controller.service";
import { DeviceContainerControllerService } from "../../../services/device-container-controller.service";
import { ContainerTypeControllerService } from "../../../services/container-type-controller.service";
import { ReferenceNameControllerService } from "../../../services/reference-name-controller.service";
import { InternalUserControllerService } from "../../../services/internal-user-controller.service";
import { AnalyticsControllerService } from "../../../services/analytics-controller.service";
import {
  UserRightsService,
  UserAccess,
  userAccess
} from "../../../services/user-rights.service";

import { SET_TYPES } from "../../../stores/data_store/containers-type-store";
import { SET_STATUS } from "../../../stores/data_store/status-store";
import {
  SET_DEVICES,
  SET_CONTAINERS
} from "../../../stores/data_store/filters-store";

import { ContainerTypeDTO } from "../../../models/container-type";
import { CurrentStateDTO } from "../../../models/current-state";
import { Status } from "../../../models/status";

import { SimpleDialogComponent } from "../../dialog/simple-dialog.component";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IncidentDTO } from "../../../models/incident";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild("tabgroup") tabGroup!: MatTabGroup;

  private subscription: Subscription = new Subscription();

  containerTypes: Array<ContainerTypeDTO>;
  containerTypesSubscription: Observable<any>;
  deviceContainerList: Array<CurrentStateDTO>;
  currentStateSubscription: Observable<any>;

  selectedDeviceIdFilter: string;

  showDetailMap: boolean;
  currentStateMap: CurrentStateDTO;
  currentStateList: CurrentStateDTO;
  incidents: Array<IncidentDTO>;

  display: boolean;

  ifIE = false;

  login: string;

  userName: string;

  selectedIndex: number = 0;
  selectedTab: string = userAccess.KPI;

  rights: UserAccess;
  interval: number;

  chargeProvisioning: boolean;
  batterySelected: boolean = false;

  managerLoading: boolean | undefined;
  mapLoading: number | undefined;
  protected analyticsRemainingTime: number | undefined;
  protected hoverLoadingMap: string;
  protected hoverTimeAnalytics: string;
  protected preventIndexChange: boolean;
  protected displayTooltipAndCloseForAnalytics: boolean = false;
  protected textAnalyticsTooltip: string;
  private tabLabels: UserAccess = userAccess;
  private timeoutAnalatyticsTab: number = 30; // time in minutes before analytics tab timeout and is destroyed

  constructor(
    private _router: Router,
    private _userService: InternalUserControllerService,
    private _userRightsService: UserRightsService,
    public dialog: MatDialog,
    private store: Store<any>,
    private _containerTypeControllerService: ContainerTypeControllerService,
    public containerTypeControllerService: ContainerTypeControllerService,
    public referenceNameControllerService: ReferenceNameControllerService,
    public incidentHistoricalControllerService: IncidentHistoricalControllerService,
    public analyticsControllerService: AnalyticsControllerService,
    private deviceCtrl: DeviceContainerControllerService
  ) {
    const ua = window.navigator.userAgent;
    // Test values; Uncomment to check result …
    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
    // IE 12 / Spartan
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
    // Edge (IE 12+)
    // eslint-disable-next-line max-len
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      // return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      this.ifIE = true;
    }
    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      // var rv = ua.indexOf('rv:');
      // return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      this.ifIE = true;
    }
    this.rights = this._userRightsService.getUserRights();
  }

  ngOnInit() {
    this.login = localStorage.getItem("initials");
    this.userName = localStorage.getItem("username");
    this._userService.setIsLogin(true);
    this.containerTypesSubscription = this.store.select("ctTypes").pipe(
      map((ctTypesData: Array<ContainerTypeDTO>) => {
        this.containerTypes = ctTypesData;
      })
    );

    this.subscription.add(
      this.referenceNameControllerService
        .getIsLoading()
        .subscribe((isLoading) => {
          this.managerLoading = isLoading;
          if (
            this.managerLoading === false &&
            this.selectedTab === this.tabLabels.MANAGER
          ) {
            this.referenceNameControllerService.setIsLoading(undefined);
          }
        })
    );

    this.subscription.add(
      this.incidentHistoricalControllerService
        .getIsLoading()
        .subscribe((loading) => {
          this.mapLoading = loading;
          this.hoverLoadingMap =
            loading === -1 ? "Timeout error" : `Loading : ${loading} %`;
          if (
            this.mapLoading === 100 &&
            this.selectedTab === this.tabLabels.MAP
          )
            this.incidentHistoricalControllerService.setIsLoading(undefined);
        })
    );

    this.subscription.add(
      this.analyticsControllerService
        .getRemainingTime()
        .subscribe((remainingTime) => {
          this.analyticsRemainingTime = remainingTime;
          this.displayTooltipAndCloseForAnalytics =
            this.analyticsRemainingTime !== undefined &&
            this.analyticsRemainingTime > 0;
          if (this.displayTooltipAndCloseForAnalytics)
            this.textAnalyticsTooltip = `Remaining time :<br> ${this.analyticsRemainingTime} min`;
          if (this.analyticsRemainingTime === 0) {
            this.analyticsRemainingTime = undefined;
            this.analyticsControllerService.setRemainingTime(undefined);
          }
        })
    );

    this.initStatus();
    this.initContainerTypes();
    this.initDevices();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.interval);
  }

  isAdmin(event: string): boolean {
    return this._userRightsService.getUserRightLevel(event);
  }

  initStatus() {
    this.store.dispatch({
      type: SET_STATUS,
      payload: Status.GetStatusList()
    });
  }

  initDevices() {
    this.subscription.add(
      this.deviceCtrl.getAllDeviceIds().subscribe((result) => {
        this.store.dispatch({ type: SET_DEVICES, payload: result.deviceIds });
      })
    );
  }

  initContainerTypes() {
    let ctTypes = new Array<ContainerTypeDTO>();
    this.subscription.add(
      this._containerTypeControllerService
        .getContainerTypes()
        .subscribe((result) => {
          ctTypes = result;
          this.store.dispatch({ type: SET_TYPES, payload: ctTypes });
          this.store.dispatch({ type: SET_CONTAINERS, payload: ctTypes });
          this.subscription.add(
            this._containerTypeControllerService
              .getPreferredContainerTypes()
              .subscribe((preferred) => {
                this._containerTypeControllerService.setNumberPreferredContainerTypes(
                  preferred
                );
                const preferredCtTypesCode = preferred.map((p) => p.code);
                for (const ctType of ctTypes) {
                  ctType.checked = preferredCtTypesCode.includes(ctType.code);
                }
                this.store.dispatch({ type: SET_TYPES, payload: ctTypes });
              })
          );
        })
    );
  }

  logoutClicked() {
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      data: {
        title: "Log out",
        text: "Are you sure you want to logout ?",
        yes: "Yes",
        no: "No"
      }
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._userService.logout();
          clearInterval(this.interval);
          this._router.navigate(["/"], {});
        }
      })
    );
  }

  onNavChange(event: any) {
    if (event.deviceIdFilter) {
      this.selectedDeviceIdFilter = event.deviceIdFilter;
    }

    this.showDetailMap = event.showDetailMap;
    this.currentStateMap = event.currentStateMap;
    this.currentStateList = event.currentStateList;
    this.incidents = event.incidents;

    this.display = event.display;
    this.selectedTab = event.tab;

    const tabs = this.tabGroup._tabs.toArray(); // Obtenez la liste des onglets
    const tab = tabs.find((tab) => tab.ariaLabel === event.tab);
    if (tab) {
      this.selectedIndex = tabs.indexOf(tab);
    }
  }

  public selectedTabChange(tabGroup, event): void {
    const index: number = event.index;
    const tab: string = event.tab.ariaLabel;

    if (this.preventIndexChange) {
      tabGroup.selectedIndex = this.selectedIndex;
      this.preventIndexChange = false;
      return;
    }
    this.selectedTab = tab;
    this.selectedIndex = index;
    if (tab !== this.tabLabels.LIST) {
      this.selectedDeviceIdFilter = null;
      this.currentStateList = null;
      this.display = null;
    }
    if (tab !== this.tabLabels.MAP) {
      this.currentStateMap = null;
      this.showDetailMap = null;
    }
    if (tab === this.tabLabels.PROVISIONING) {
      this.chargeProvisioning = true;
    } else {
      this.chargeProvisioning = false;
    }
    if (tab !== this.tabLabels.INCIDENT) {
      this.incidents = null;
    }
    if (
      this.selectedTab === this.tabLabels.MANAGER &&
      this.managerLoading === false
    ) {
      this.referenceNameControllerService.setIsLoading(undefined);
    }
    if (
      this.selectedTab === this.tabLabels.MAP &&
      (this.mapLoading === 100 || this.mapLoading === -1)
    ) {
      this.incidentHistoricalControllerService.setIsLoading(undefined);
    }
    if (
      this.selectedTab !== this.tabLabels.ANALYTICS &&
      this.analyticsRemainingTime === -1
    ) {
      //correspond to when there is data in analytics tab and user just moved to another tab
      this.analyticsControllerService.setRemainingTime(
        this.timeoutAnalatyticsTab
      );
    } else if (
      this.selectedTab === this.tabLabels.ANALYTICS &&
      this.analyticsRemainingTime >= 0
    ) {
      //correspond to when user come back to analytics tab during allowed time
      this.analyticsControllerService.setRemainingTime(-1);
    }
  }

  onCloseAnalytics() {
    if (this.selectedTab !== this.tabLabels.ANALYTICS)
      this.preventIndexChange = true;
    this.analyticsControllerService.setRemainingTime(undefined);
  }
}
