// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// PRODUCTION

export const environment = {
  production: true,
  apiBaseUrl:
    "https://production-back-rest.psa-tnt-cluster-productio-d1a83b7179dc83833b85fae059671663-0000.eu-de.containers.appdomain.cloud/",
  maxHistoricDateInDay: 10,
  globalRefreshInMs: 60000,
  refreshMapListInLs: 30000,
  lastHoursForMvtHistory: 96
};
